.c-btn button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 375px;
  height: 85px;
  padding: 20px 40px;
  border-radius: 20px;
  background-color: #2182c3;
  border-width: 0;
  box-shadow: 2px 2px 7px rgb(0 0 0 / 30%);
  color: #fff;
  font-size: 21px;
  font-weight: bold;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.c-btn.-rd button {
  background-color: #ff0000;
  color: #fff;
}

.c-btn button::before {
  display: block;
  content: "＞";
  position: absolute;
  right: 15px;
  top: 50%;
  font-size: 25px;
  font-weight: bold;
  color: #fff;
  transform: translateY(-50%) scaleX(0.3);
}

.p-FieldLabel {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.login-syn__logo span::after {
  content: None;
}
